CSS.insert(`
	ui-window { 
		--window-padding: 20px;
		--window-radius: 5px;

		--padding-left: var(--window-padding);
		--padding-right: var(--window-padding);

		display: flex; 
		position: fixed; 
		top: 0; left: 0; 
		min-width: min-content;
		
		background: #fff; color: #000; border-radius: var(--window-radius); z-index: 1001; 
		transition: transform 0.1s ease-in-out; 
	}
	
	ui-window:not([overlay=true]) { box-shadow: #888 0px 0px 32px, #aaa 0px 0px 6px;  }
	ui-window:not(.primary)[overlay=true] { box-shadow: rgba(34,34,34,0.4) 0px 0px 64px, rgba(34,34,34,0.1) 0px 0px 16px; }
	ui-window.isFullWidth { border-radius: 0; }
	ui-window[data-active=false] { filter: brightness(0.7); }
	ui-window[data-modal=true] { outline: 8px solid rgba(0,0,0,0.1); }
	body.Mobile ui-window { position: absolute; }
	ui-window > [tabindex] { outline: none; }

	window-tip { display: block; position: absolute; width: 0; height: 0; border: 14px solid rgba(0,0,0,0); }
	ui-window[data-tip=top] window-tip { border-bottom-color: #fff; }
	ui-window[data-tip=left] window-tip { border-right-color: #fff; drop-shadow(4px 0px 2px rgba(0,0,0,0.3)); }
	ui-window[data-tip=right] window-tip { border-left-color: #fff; drop-shadow(4px 0px 2px rgba(0,0,0,0.3)); }
	ui-window[data-tip=bottom] window-tip { border-top-color: #fff; }

	ui-window[data-scroll=true] window-frame { overflow-y: scroll; overflow-x: hidden; }
	body.Desktop ui-window[data-scroll=true] window-frame { margin-right: -12px; padding-right: calc(var(--window-padding) + 6px); }
	body.Desktop ui-window[data-scroll=true][data-tip=right] window-frame { direction: rtl; margin-left: -12px; padding-left: calc(var(--window-padding) + 6px); margin-right: 0; padding-right: var(--window-padding); }
	body.Desktop ui-window[data-scroll=true] window-frame * { direction: ltr; }
	body.Desktop ui-window[data-scroll=true] window-frame::-webkit-scrollbar { width: 6px; }
	body.Desktop ui-window[data-scroll=true] window-frame::-webkit-scrollbar-thumb:vertical { background-color: #fff; }
	body.Desktop ui-window[data-scroll=true] window-frame::-webkit-scrollbar-track-piece { background-color: rgba(0,0,0,0); margin: 0; }
	

	/* Window frame styles for header, content and footer */

	window-frame { 
		display: grid; 
		grid-template-rows: min-content 1fr min-content;
		grid-template-areas: "header" "main" "footer";
		padding: 0;
		height: auto;
	}

	window-frame > header { grid-area: header; padding: var(--window-padding) var(--window-padding) 0; position: relative; }
	window-frame > header .widget.header h3 { overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
	window-frame > header > slot { display: block; }
	window-frame > header > slot .widget.spinner.horizontal { position: absolute; top: var(--window-padding); right: var(--window-padding); }
	window-frame > header.padding {
		padding-bottom: var(--window-padding);
	}
	window-frame > header.separator {
		padding-bottom: 4px; 
		margin-bottom: var(--window-padding);
		border-bottom: 1px solid #e6e6e6;
	}

	window-frame > main { grid-area: main; padding: 0 var(--window-padding) var(--window-padding); overflow: visible; }
	window-frame > main > window-content { display: block; height: 100%; }
	window-frame > main > window-content > .widget:last-child { margin-bottom: 0; }

	window-frame > footer { grid-area: footer; text-align: right; border-top: 1px solid #e6e6e6; padding: var(--window-padding); display: flex; justify-content: space-between; }
	window-frame > footer:has(slot:empty + nav:empty) { display: none; }

	window-frame > footer slot { display: flex; gap: 8px; flex: 1; }
	window-frame > footer slot > .widget { margin: 0; }
	window-frame > footer slot > .widget.dropdown { margin-right: auto; }
	window-frame > footer slot > .widget.dropdown > button { height: 28px; line-height: 28px; }
	window-frame > footer slot > .widget.dropdown[data-style=columns] > label { width: auto; }
	window-frame > footer:not(slot + nav:empty) > slot { margin-right: 16px; }
	window-frame > footer nav { display: flex; gap: 8px; }
	window-frame > footer nav > .widget.button { margin: 0; }
		
	ui-window .contentWrapper { margin: 0 calc(0px - var(--window-padding)); padding: 0 var(--window-padding); }


	/* Overflow scrollbar styling for custom scrollbars */

	ui-window[data-overflow=true] window-frame > main { overflow-x: scroll; overflow-y: hidden; }
	body[data-scroll=custom] ui-window[data-overflow=true] window-frame > main { padding-bottom: 17px; margin-bottom: -3px; }
	body[data-scroll=custom] ui-window[data-overflow=true] window-frame > main::-webkit-scrollbar { height: 6px; }
	body[data-scroll=custom] ui-window[data-overflow=true] window-frame > main::-webkit-scrollbar-track-piece { margin-left: var(--window-padding); margin-right: var(--window-padding); }
	body[data-scroll=custom] ui-window[data-overflow=true] window-frame > footer { border-color: transparent; }


	/* Overflow scrollbar simulation for iOS */

	body.iOS ui-window[data-overflow=true] {
		--overflow-width: 100%;
		--overflow-x: 0;
	}
	body.iOS ui-window[data-overflow=true] window-frame::after {
		grid-area: footer;

		content: '';
		position: relative;
		transform: translate(var(--overflow-x),-2px);
		width: var(--overflow-width);
		height: 3px;
		border-radius: 4px;
		background: #aaa;
	}
	body.iOS ui-window[data-overflow=true] window-frame > main { 
		padding-bottom: 4px;
		margin-bottom: -4px;
	}
	body.iOS ui-window[data-overflow=true] window-frame > footer { 
		border-color: transparent; 
	}

`);

Window = Class.create();
Window.id = 0;
Window.count = 0;
Window.list = [];
Window.prototype = {
	initialize: function(options) {
		this.options = Object.assign({
			pointer: 		null,
			width: 			400,
			height: 		null,
			left:			null,
			right:			null,
			top:			null,
			padding:		20,
			header:			{},
			content: 		{},
			title: 			null,
			icon:			null,
			className:		null,
			onClose: 		null,
			onShow: 		null,
			modal:			false,
			animate:		true,
			overlay:		true
		}, options || {});

		this.options.header = Object.assign({
			title:			this.options.title,
			icon:			this.options.icon,
			separator:		false,
			padding:		false
		}, this.options.header || {})

		this.options.content = Object.assign({
			height:			null,
		}, this.options.content || {})


		this._internal = {
			id: ++Window.id,

			tip: null,
			wrapper: null,
			frame: null,
			pointer: {
				side:		null,
				target:		null,
				distance:	30,
				corner:		30,
				margin:		28,
				size:		28
			},

			state: {
				window:		{ top: 0, left: 0, width: 0, height: 0, side: null },
				target: 	{ top: 0, left: 0, width: 0, height: 0 }
			}
		}

		this.closing = false;

		this.window = document.createElement('ui-window');
		this.window.dataset.id = this._internal.id;
		this.window.dataset.modal = this.options.modal ? 'true' : 'false';

		this.window.style.width = `${this.options.width + this.options.padding + this.options.padding}px`;

		if (this.options.height) {
			this.window.style.height = `${this.options.height + this.options.padding + this.options.padding}px`;
		}

		if (this.options.padding != 20) {
			this.window.style.setProperty('--window-padding', `${this.options.padding}px`);
		}

		if (this.options.className) {
			this.window.classList.add(...this.options.className.split(' '));
		}

		if (this.options.pointer) {
			let pointer = {};
			
			/* If the pointer is a string, it is a side */

			if (typeof this.options.pointer == 'string') {
				pointer.side = this.options.pointer;
			}

			/* If the pointer is a DOM element */

			else if(this.options.pointer instanceof HTMLElement) {
				pointer.target = this.options.pointer;
			}

			/* If the pointer is a widget, find the container */

			else if (typeof this.options.pointer == 'object' && typeof this.options.pointer.container != 'undefined') {
				pointer.target = this.options.pointer;
			}

			/* If the pointer is an object, it is a configuration object */

			else if (typeof this.options.pointer == 'object') {
				pointer = this.options.pointer;
			}

			this._internal.pointer = Object.assign(this._internal.pointer, pointer);

			/* Create the tip */

			this._internal.tip = document.createElement('window-tip');
			this.window.appendChild(this._internal.tip);
		}


		this._internal.frame = document.createElement('window-frame');
		this.window.appendChild(this._internal.frame);


		/* Header */

		this._internal.header = document.createElement('header');
		this._internal.frame.appendChild(this._internal.header);

		this.windowTitle = new Widgets.Header(this._internal.header, {
			title:	this.options.header.title || '',
			icon:	this.options.header.icon,
			style:	'window',
		});

		this._internal.header.classList.toggle('separator', this.options.header.separator);
		this._internal.header.classList.toggle('padding', this.options.header.padding);

		this.header = document.createElement('slot');
		this._internal.header.appendChild(this.header);


		/* Content */

		this._internal.wrapper = document.createElement('main');
		this._internal.frame.appendChild(this._internal.wrapper);
		this._internal.wrapper.addEventListener('scroll', _ => {
			this.updateOverflowScrolller();
		});

		this.container = document.createElement('window-content');
		this.container.style.width = `${this.options.width}px`;

		if (this.options.content.height) {
			this.container.style.height = this.options.content.height + 'px';
		}
		
		this._internal.wrapper.appendChild(this.container);		
			

		/* Footer */

		this._internal.footer = document.createElement('footer');
		this._internal.frame.appendChild(this._internal.footer);

		this.footer = document.createElement('slot');
		this._internal.footer.appendChild(this.footer);

		this.navigation = document.createElement('nav');
		this._internal.footer.appendChild(this.navigation);


		/* Trap focus */
		
		this.trapFocus(this._internal.frame);

		this.window.hide();
		document.body.appendChild(this.window);

		/* for backwards compatibility */
		this.contents = this.container;
	},

	focus: function() {
		if (this._internal.focusTrap.frontTarget) {
			this._internal.focusTrap.frontTarget.focus();
		}
	},

	show: function() {
		Window.count++;
		Window.list.push(this);
		Window.render();

		this.window.style.zIndex = ((Window.count + 1) * 1000) + 1;


		/* If an element outside of the window still has focus, blur it */
		this.previousFocus = document.activeElement;

		if (document.activeElement) {
			document.activeElement.blur();
		}


		requestAnimationFrame(() => {

			/* Determine positioning */

			this.positioning = 'center';

			if (this.options.top && (this.options.left || this.options.right)) {
				this.positioning = 'fixed';
			}
			else if (this._internal.pointer.target) {
				this.positioning = 'auto';
			}


			/* Notify others that a window is opening */

			document.fire('window:open', this);


			/* Position window */

			this.positionWindow();

			this.window.dataset.overlay = this.options.overlay ? 'true' : 'false';

			if (this.options.animate) {
				Fx.appear(this.window, {

					after: function() {
						this.focus();

						if (this.options.onShow) {
							this.options.onShow();
						}

						this.startInspection();
					}.bind(this)
				})
			} else {
				this.window.show();

				this.focus();
				
				if (this.options.onShow) {
					this.options.onShow();
				}

				this.startInspection();
			}

			if (this.options.overlay) {
				if (Overlay.count == 0) {
					this.window.classList.add('primary');
				}

				this.overlay = new Overlay ({
					onHide: this.options.modal ? null : this.close.bind(this),
					zIndex: ((Window.count + 1) * 1000) - 1,
					animate: this.options.animate,
					owner: this.window
				});
			}
		});
	},

	trapFocus: function(element) {
		this._internal.focusTrap = {};

		this._internal.focusTrap.frontCatch = new Element('div');
		this._internal.focusTrap.frontCatch.tabIndex = 0;
		this._internal.focusTrap.frontCatch.onfocus = () => this._internal.focusTrap.endTarget.focus();
		element.insertAdjacentElement('beforebegin', this._internal.focusTrap.frontCatch)

		this._internal.focusTrap.frontTarget = new Element('div');
		this._internal.focusTrap.frontTarget.tabIndex = -1;
		element.insertAdjacentElement('beforebegin', this._internal.focusTrap.frontTarget)

		this._internal.focusTrap.endCatch = new Element('div');
		this._internal.focusTrap.endCatch.tabIndex = 0;
		this._internal.focusTrap.endCatch.onfocus = () => this._internal.focusTrap.frontTarget.focus();
		element.insertAdjacentElement('afterend', this._internal.focusTrap.endCatch)

		this._internal.focusTrap.endTarget = new Element('div');
		this._internal.focusTrap.endTarget.tabIndex = -1;
		element.insertAdjacentElement('afterend', this._internal.focusTrap.endTarget)
	},

	startInspection: function() {
		window.addEventListener('resize', this.viewPortHasResized.bind(this));
		window.addEventListener('orientationchange', this.viewPortHasRotated.bind(this));

		if (typeof ResizeObserver == 'undefined') {
			this.resizeInterval = window.setInterval(this.manuallyCheckForChanges.bind(this), 100)
			return;
		}

		this.observer = new ResizeObserver(entries => {
			requestAnimationFrame(_ => {
				entries.forEach(entry => {
					if (entry.target == this.window) {
						
						/* Check if the window has been resized */

						if (entry.contentRect.width != this._internal.state.window.width || 
							entry.contentRect.height != this._internal.state.window.height) 
						{
							this.windowSizeHasChanged();
						}
					}

					if (entry.target == this._internal.pointer.target) {

						/* Check if the target has been resized or repositioned */

						let rect = entry.target.getBoundingClientRect();

						if (rect.left != this._internal.state.target.left ||
							rect.top != this._internal.state.target.top ||
							rect.width != this._internal.state.target.width ||
							rect.height != this._internal.state.target.height) 
						{
							this.positionWindowLinkedToElement();
						}
					}
				});
			});
		});

		this.observer.observe(this.window);
		if (this.positioning == 'auto') {
			this.observer.observe(this._internal.pointer.target);
		}
	},

	stopInspection: function() {
		window.removeEventListener('resize', this.viewPortHasResized.bind(this));
		window.removeEventListener('orientationchange', this.viewPortHasRotated.bind(this));
		
		if (this.resizeInterval) {
			window.clearInterval(this.resizeInterval);
		}

		if (this.observer) {
			this.observer.disconnect();
		}
	},

	manuallyCheckForChanges: function() {
		if (!this.previousWindowDimensions) {
			this.previousWindowDimensions = this.window.getBoundingClientRect();
			return;
		}

		var currentWindowDimensions = this.window.getBoundingClientRect();

		if (Math.abs(currentWindowDimensions.width - this.previousWindowDimensions.width) > 2 || 
			Math.abs(currentWindowDimensions.height - this.previousWindowDimensions.height) > 2) 
		{
			this.windowSizeHasChanged();

			this.previousWindowDimensions = currentWindowDimensions;
		}


		if (this.positioning == 'auto') {
			if (!this.previousPointerDimensions) {
				this.previousPointerDimensions = this._internal.pointer.target.getBoundingClientRect();
				return;
			}

			var currentPointerDimensions = this._internal.pointer.target.getBoundingClientRect();

			if (currentPointerDimensions.left != this.previousPointerDimensions.left) {
				this.positionWindowLinkedToElement();

				this.previousPointerDimensions = currentPointerDimensions;
			}
		}
	},

	viewPortHasResized: function() {
		if (System.Type.Mobile && !System.Type.Tablet) {
			return;
		}

		if (this.positioning == 'center') {
			this.positionWindowCentered();
		}

		if (this.positioning == 'auto') {
			this.positionWindowLinkedToElement();
		}
	},

	viewPortHasRotated: function() {
		if (this.positioning == 'center') {
			setTimeout(this.positionWindowCentered.bind(this), 100);
		}

		if (this.positioning == 'auto') {
			this.positionWindowLinkedToElement();
		}
	},

	windowSizeHasChanged: function() {
		this.positionWindow();
	},


	positionWindow: function() {
		if (this.positioning == 'center') {
			this.positionWindowCentered();
		}

		if (this.positioning == 'fixed') {
			this.positionWindowFixed();
		}

		if (this.positioning == 'auto') {
			this.positionWindowLinkedToElement();
		}
	},

	positionWindowLinkedToElement: function() {
		var pointerDimensions = this._internal.pointer.target.getBoundingClientRect();
		var windowDimensions = this.window.getDimensions();


		// Find side with the most room

		var side = this._internal.pointer.side;

		if (!side) {
			var sides = [
				[ 'top', pointerDimensions.top - windowDimensions.height ],
				[ 'left', pointerDimensions.left - windowDimensions.width ],
				[ 'right', window.innerWidth - pointerDimensions.left - pointerDimensions.width - windowDimensions.width ],
				[ 'bottom',	window.innerHeight - pointerDimensions.top - pointerDimensions.height - windowDimensions.height ]
			]

			let candidates = sides.filter(function(s) { return s[1] > 0 }).map(s => s[0]);
			let optimal = sides[sides.reduce(function(p, c, i) { return c[1] > sides[p][1] ? i : p }, 0)][0]

			if (this._internal.state.window.side) {

				/* When repositioning, we want to keep the current side if it is still a candidate */

				if (candidates.includes(this._internal.state.window.side)) {
					side = this._internal.state.window.side;
				}
				else {
					side = optimal;
				}
			}
			else {
				side = optimal;
			}
		}

		// Based on the side determine the quadrant where we are going to show the dialog

		var quadrant;

		if (side == 'left' || side == 'right') {
			quadrant = pointerDimensions.top + (pointerDimensions.height / 2) < window.innerHeight / 2 ? 'top' : 'bottom';
		}

		if (side == 'top' || side == 'bottom') {
			quadrant = pointerDimensions.left + (pointerDimensions.width / 2) < window.innerWidth / 2 ? 'left' : 'right';
		}


		var left, right, top, bottom;
		var distance = this._internal.pointer.distance;
		var size = this._internal.pointer.size;
		var margin = this._internal.pointer.margin;
		var pointer = true;


		// Determine the position on the axis running through the pointer object

		switch(side) {
			case 'left': left = pointerDimensions.left - windowDimensions.width - distance; break;
			case 'right': left = pointerDimensions.left + pointerDimensions.width + distance; break;
			case 'top': top = pointerDimensions.top - windowDimensions.height - distance; break;
			case 'bottom': top = pointerDimensions.top + pointerDimensions.height + distance; break;
		}

		// Determine the position on the axis crossing the previous axis

		switch(quadrant) {
			case 'top': top = Math.max(margin, (pointerDimensions.top + (pointerDimensions.height / 2)) - (windowDimensions.height / 2)); break;
			case 'bottom': bottom = Math.max(margin, window.innerHeight - ((pointerDimensions.top + (pointerDimensions.height / 2)) + (windowDimensions.height / 2))); break;
			case 'left': left = Math.max(margin, (pointerDimensions.left + (pointerDimensions.width / 2)) - (windowDimensions.width / 2)); break;
			case 'right': right = Math.max(margin, window.innerWidth - ((pointerDimensions.left + (pointerDimensions.width / 2)) + (windowDimensions.width / 2))); break;
		}


		// Always use top and left for positioning

		if (right) {
			left = window.innerWidth - windowDimensions.width - right;
		}

		if (bottom) {
			top = window.innerHeight - windowDimensions.height - bottom;
		}


		// Make sure our window does not go offscreen to the left or right

		if (left < 20) {
			left = 20;
		}

		if (left + windowDimensions.width > window.innerWidth - 20) {
			left = window.innerWidth - windowDimensions.width - 20;
		}


		/* Determine if we need to make the window full width */

		let maximumWidth = document.body.clientWidth - this.options.padding - this.options.padding;

		if (System.Type.Mobile) {
			maximumWidth = document.body.clientWidth;
		}

		let windowHasOverflow = this.options.width + this.options.padding + this.options.padding > maximumWidth;

		if (windowHasOverflow) {
			left = (document.body.clientWidth - maximumWidth) / 2;
			
			this.window.dataset.overflow = 'true';
			this.window.style.width = `${maximumWidth}px`;

			requestAnimationFrame(_ => {
				this.updateOverflowScrolller();
			});
		}
		else {
			delete this.window.dataset.overflow;
			this.window.style.width = `${this.options.width + this.options.padding + this.options.padding}px`;
		}

		this.window.classList.toggle('isFullWidth', windowHasOverflow && maximumWidth == document.body.clientWidth);


		// Make sure our window does not go offscreen on the top or bottom 

		if (top < 0 || top + windowDimensions.height > window.innerHeight) {
			top = distance;

			let height = window.innerHeight - margin - margin - this.options.padding - this.options.padding;

			if (windowDimensions.height > height) {
				this._internal.frame.style.maxHeight = `${height}px`;
				this.window.dataset.scroll = 'true';
			}
		}


		// Check if the tip overshot the target, if so, hide the tip

		if (side == 'top') {
			if (top + windowDimensions.height + (size / 2) > pointerDimensions.top + pointerDimensions.height) {
				pointer = false;
			}
		}

		if (side == 'bottom') {
			if (top - (size / 2) < pointerDimensions.top) {
				pointer = false;
			}
		}

		if (side == 'left') {
			if (left + windowDimensions.width + (size / 2) > pointerDimensions.left + pointerDimensions.width) {
				pointer = false;
			}
		}

		if (side == 'right') {
			if (left - (size / 2) < pointerDimensions.left) {
				pointer = false;
			}
		}

		// Position window 

		this.window.style.transform = 'translate(' + parseInt(left, 10) + 'px, ' + parseInt(top, 10) + 'px)';


		// Set the correct type style 

		this.window.dataset.tip = '';

		if (pointer) {
			switch(side) {
				case 'left': this.window.dataset.tip = 'right'; break;
				case 'right': this.window.dataset.tip = 'left'; break;
				case 'top': this.window.dataset.tip = 'bottom'; break;
				case 'bottom': this.window.dataset.tip = 'top'; break;
			}
			
			// Position tip

			var corner = this._internal.pointer.corner;
			var position;

			if (side == 'left' || side == 'right') {
				position = (pointerDimensions.top + (pointerDimensions.height / 2) - top - (size / 2));
				position = Math.max(corner, Math.min(windowDimensions.height - corner - size, position));

				this._internal.tip.style.transform = 'translateY(' + position + 'px)';
				this._internal.tip.style.top = '0px';
				this._internal.tip.style.left = '';
			}

			if (side == 'top' || side == 'bottom') {
				position = (pointerDimensions.left + (pointerDimensions.width / 2) - left - (size / 2));
				position = Math.max(corner, Math.min(windowDimensions.width - corner - size, position));

				this._internal.tip.style.transform = 'translateX(' + position + 'px)';
				this._internal.tip.style.top = '';
				this._internal.tip.style.left = '0px';
			}

			this._internal.tip.style.borderWidth = (size / 2) + 'px';
			this._internal.tip.style[this.window.dataset.tip] = (1 - size) + 'px';
		}


		/* Store state of current position */

		this._internal.state.window = {
			top: top,
			left: left,
			width: windowDimensions.width,
			height: windowDimensions.height,
			side: side
		}

		this._internal.state.target = {
			top: pointerDimensions.top,
			left: pointerDimensions.left,
			width: pointerDimensions.width,
			height: pointerDimensions.height
		}
	},

	positionWindowCentered: function() {

		/* Determine if we need to make the window full width */

		let maximumWidth = document.body.clientWidth - this.options.padding - this.options.padding;

		if (System.Type.Mobile) {
			maximumWidth = document.body.clientWidth;
		}

		let windowHasOverflow = this.options.width + this.options.padding + this.options.padding > maximumWidth;

		if (windowHasOverflow) {
			this.window.dataset.overflow = 'true';
			this.window.style.width = `${maximumWidth}px`;

			requestAnimationFrame(_ => {
				this.updateOverflowScrolller();
			});
		}
		else {
			delete this.window.dataset.overflow;
			this.window.style.width = `${this.options.width + this.options.padding + this.options.padding}px`;
		}

		this.window.classList.toggle('isFullWidth', windowHasOverflow && maximumWidth == document.body.clientWidth);


		/* Center the window */

		var dimensions = this.window.getDimensions();

		var top = Math.max(0, Math.round((window.innerHeight / 2) - (dimensions.height / 2)));
		var left = Math.round((window.innerWidth / 2) - (dimensions.width / 2));


		/* Stagger the window if we overlap another window with the same position */

		let noSpaceForOffset = this.options.width + this.options.padding + this.options.padding > maximumWidth - 20;

		if (Window.count > 1) {
			let previousWindow = null;

			for (w of Window.list) {
				if (w == this) {
					break;
				}

				previousWindow = w;
			}

			if (previousWindow) {
				let horizontalDifference = Math.abs(top - previousWindow._internal.state.window.top);

				if (horizontalDifference < 10) {
					top += 20;
				}

				if (previousWindow._internal.state.window.left == left && !noSpaceForOffset) {
					left += 20;
				}
			}
		}

		this.window.style.transform = 'translate(' + left + 'px, ' + top + 'px)';


		/* Store state of current position */

		this._internal.state.window = {
			top: top,
			left: left,
			width: dimensions.width,
			height: dimensions.height,
			side: null
		}

		this._internal.state.target = {
			top: 0,
			left: 0,
			width: 0,
			height: 0
		}
	},

	positionWindowFixed: function() {
		var dimensions = this.window.getDimensions();
		var left = this.options.left;

		if (this.options.right) {
			var left = window.innerWidth - dimensions.width - this.options.right;
		}

		this.window.style.transform = 'translate(' + left + 'px, ' + this.options.top + 'px)';

		let side = null;

		if (this._internal.pointer.side) {
			side = this._internal.pointer.side;

			if (typeof this._internal.pointer.position != 'undefined') {
				var position = this._internal.pointer.position;
				var size = this._internal.pointer.size;

				if (side == 'left' || side == 'right') {
					this._internal.tip.style.transform = 'translateY(' + position + 'px)';
					this._internal.tip.style.top = '0px';
					this._internal.tip.style.left = '';
				}

				if (side == 'top' || side == 'bottom') {
					if (this.options.right) {
						position = dimensions.width - position - size;
					}

					this._internal.tip.style.transform = 'translateX(' + position + 'px)';
					this._internal.tip.style.top = '';
					this._internal.tip.style.left = '0px';
				}

				this._internal.tip.style.borderWidth = (size / 2) + 'px';
				this._internal.tip.style[this.window.dataset.tip] = (1 - size) + 'px';
			}
		}

		this.window.dataset.tip = side || '';

		/* Store state of current position */

		this._internal.state.window = {
			top: top,
			left: left,
			width: dimensions.width,
			height: dimensions.height
		}

		this._internal.state.target = {
			top: 0,
			left: 0,
			width: 0,
			height: 0,
			side: side
		}
	},

	updateOverflowScrolller: function() {
		let width = this._internal.wrapper.clientWidth;
		let scrollWidth = this._internal.wrapper.scrollWidth;
		let scrollLeft = this._internal.wrapper.scrollLeft;

		this.window.style.setProperty('--overflow-width', `${width / scrollWidth * (width - 4)}px`);
		this.window.style.setProperty('--overflow-x', `${2 + (width / scrollWidth * scrollLeft)}px`);
	},

	close: function() {
		if (!this.closing) {
			this.closing = true;

			Window.count--;
			Window.list.pop();
			Window.render();

			if (this.options.animate) {
				Fx.fade(this.window, {
					after: 	function() {
								if (this.options.onClose) {
									this.options.onClose();
								}

								if (this.window) {
									this.window.remove();
								}

								if (this.previousFocus) {
									this.previousFocus.focus();
								}
							}.bind(this)
				});
			} else {
				this.window.hide();

				if (this.options.onClose) {
					this.options.onClose();
				}

				if (this.window) {
					this.window.remove();
				}

				if (this.previousFocus) {
					this.previousFocus.focus();
				}
			}


			if (this.overlay) {
				this.overlay.destroy();
			}
		}

		this.stopInspection();
	},

	appendChild: function(element) {
		this.contents.appendChild(element)
	},

	clear: function() {
		this.contents.innerHTML = '';
	},

	shake: function() {
		new Effect.Shake(this.window);
	},

	isOnTop: function() {
		return Window.list[Window.list.length - 1] == this;
	},

	get title() { return this.options.title; },
	set title(value) { this.options.title = this.windowTitle.value = value; },
}

Window.render = function() {
	Window.list.forEach((w, i) => {
		w.window.dataset.stack = i;
		w.window.dataset.active = Window.list.length - 1 == i ? 'true' : 'false';
	});
}