
CSS.insert(`
    .widget.chart { display: flex; position: relative; }
    .widget.chart:empty { }
    .widget.chart > div { position: absolute !important; }
`);

Widgets.Chart = Class.create();
Widgets.Chart.prototype = {
    initialize: function(parent, options) {
        this.options = options;
        this.rendered = false;

        if (typeof parent.container != 'undefined') parent = parent.container;
        this.element = new Element('div', { 'class': 'widget chart' });
        parent.appendChild(this.element);

        if (this.options.height) {
            this.element.setStyle({ height: this.options.height });
        }

        if (this.options.width) {
            this.element.setStyle({ width: this.options.width });
        }

        this.options.chart = Object.assign({
            renderTo: Element.identify(this.element),
            reflow: true,
            style: {
                fontFamily: 'system-ui, -apple-system, -apple-system-font, BlinkMacSystemFont, ".SFNSDisplay-Regular", HelveticaNeue, "SegoeUI", Helvetica, Arial, sans-serif'
            }
        }, this.options.chart || {});

        this.options.title = Object.assign({
            text: null
        }, this.options.title || {});

        this.options.credits = { enabled: false };

        if (this.options.yAxis && this.options.yAxis.title && this.options.yAxis.title.margin) {
            this.options.yAxis.title.offset = this.options.yAxis.title.margin;
        }

        if (this.options.xAxis && this.options.xAxis.title && this.options.xAxis.title.margin) {
            this.options.xAxis.title.offset = this.options.xAxis.title.margin;
        }

        if (this.isHidden()) {
            document.observe('widget:render', function(e) {
                if (!this.rendered && this.element.descendantOf(e.memo)) {
                    this.render();
                }
            }.bind(this))
        } else {
            this.render();
        }
    },

    destroy: function() {
        this.element.remove();
    },

    render: async function() {
        this.rendered = true;

        await requireAsync(['../bundles/widgets/chart']);

        var options = Object.assign({
            time: {
                timezone: 'Europe/Amsterdam',
            },
            colors: ['#4572A7', '#AA4643', '#89A54E', '#80699B', '#3D96AE', '#DB843D', '#92A8CD', '#A47D7C', '#B5CA92']
        }, this.options);

        options.legend = Object.assign({
            align: 'right',
            layout: 'horizontal',
            y: 16,
            itemStyle: { 
                color: "#333333", 
                cursor: "pointer", 
                fontSize: "12px", 
                fontWeight: "normal", 
                textOverflow: "ellipsis"
            }
        }, this.options.legend || {})

        this.chart = new Highcharts.Chart(options);
    },

    isHidden: function() {
        return this.element.offsetHeight == 0;
    }
}