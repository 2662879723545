Widgets.Separator = Class.create(Widgets.Base, {
    name:		'separator',

    defaults:	{
        width:		'properties',
        type:		'line',
        style:		null,
    },

    css: `
        .widget.separator hr { margin: 8px 0; border-left: none; border-right: none; border-top: 1px solid #d6d6d6; border-bottom: 1px solid #f6f6f6; }
        .widget.separator hr.normalWidth { margin-left: 0px; margin-right: 0px; }
        
        .widget.separator hr.fullWidth, 
        .widget.separator hr.pageWidth,
        .widget.separator hr.propertiesWidth { margin-left: calc(0px - var(--padding-left)); margin-right: calc(0px - var(--padding-right)); }
        
        .widget.separator hr.sidebarWidth { margin-left: -30px; margin-right: -20px; }
        .widget.separator hr.panelWidth { margin-left: -10px; margin-right: -10px; }
        .widget.separator hr.sidebarWidth { margin-left: -30px; margin-right: 0; }
        .widget.separator hr.leftWidth { margin-left: -20px; }
        .widget.separator hr.rightWidth { margin-right: -20px; }

        .widget.separator hr.lineType { margin-top: 20px; margin-bottom: 20px; border-top: none; border-bottom: 1px solid #e6e6e6; }
        .widget.separator hr.panelType { margin-top: 0; margin-bottom: 0; border-bottom: 1px solid rgba(0,0,0,0.05); border-top: none; }
        .widget.separator hr.toolbarType { border: none; border-top: 1px solid #eaeaea; margin-top: 0px; margin-bottom: 20px; display: block; height: 10px; background: linear-gradient(to bottom, rgba(0,0,0,0.03) 0px, rgba(255,255,255,0) 10px); }
        
        .widget.separator hr.gradientType { 
            border: none; 
            border-top: 1px solid #ddd;
            margin-top: 20px; 
            margin-bottom: 0; 
            display: block; 
            height: 30px; 
            background: linear-gradient( rgb(246, 246, 246) 1px, rgba(255, 255, 255, 0) 7px)
        }
        
        .widget.separator hr.reverseGradientType { border: none; border-bottom: 1px solid #eaeaea; margin-top: 0; margin-bottom: 0; display: block; height: 30px; background: linear-gradient(to top, rgb(245,245,245) 0%, rgba(255,255,255, 0) 70%); margin-bottom: 30px; }
        .widget.separator hr.invisibleType { border: none; height: 20px; }

        [data-name="toolbar"] > .widget.separator { display: inline-block; }
        [data-name="toolbar"] > .widget.separator hr { display: inline-block; margin: 0; border: none; width: 30px; }
    `,

    initWidget: function() {
        var seperator = new Element('hr');
        this.insert(seperator);

        if (this.options.width) seperator.classList.add(this.options.width + 'Width');
        if (this.options.type) seperator.classList.add(this.options.type + 'Type');
        if (this.options.style) seperator.setStyle(this.options.style);
    }
});