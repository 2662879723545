	CSS.insert(`
		.widget.tabbedPanel { display: flex; flex-direction: column; margin: 0; }
		.widget.tabbedPanel.verticalType { flex-direction: row; }
		.widget.tabbedPanel > .panels { flex: 1; }
	`);

	Widgets.TabbedPanels = Class.create();
	Widgets.TabbedPanels.prototype = {
		initialize: function(parent, options) {
			this.options = Object.assign({
				className:		null,
				type:			'panel',
				size:			null,
				linked:			false,
				selected:		null,
				autoSelect:		true,
				content:		null,
				onChange:		null,
				panels:			[]
			}, options || {});

			if (this.options.linked) {
				Widgets.TabbedPanels.register(this.options.linked, this);
			}

			this.items = [];
			this.current = null;

			var navigation = null;
			var content = null;

			if (parent instanceof Page.Sidebar || parent instanceof Page.Tabs) {
				navigation = parent.navigation;
				content = parent.content;
			}


			else if (parent instanceof Layout.Sidebar) {
				navigation = parent.left;
				content = parent.right;
			}


			else {
				if (typeof parent.container != 'undefined') parent = parent.container;
				this.container = new Element('div', { 'class': 'widget tabbedPanel ' + this.options.type + 'Type' });
				parent.appendChild(this.container);

				if (this.options.className) this.container.classList.add(this.options.className);

				navigation = this.container;

				if (this.options.content) {
					content = this.options.content;
				} else {
					content = this.container;
				}
			}

			this.tabs = new Widgets.TabBar(navigation, { type: this.options.type, size: this.options.size });

			this.panels = new Element('div', { 'class': 'panels ' + this.options.type + 'Type' });
			content.appendChild(this.panels);

			var first = null;
			var selected = null;

			for (var i = 0; i < this.options.panels.length; i++) {
				if (first == null && (typeof this.options.panels[i].visible == 'undefined' || this.options.panels[i].visible)) {
					first = i;
				}

				if (typeof this.options.panels[i].id != 'undefined' && this.options.panels[i].id === this.options.selected) {
					selected = i;
				}

				if (typeof this.options.panels[i].selected != 'undefined' && this.options.panels[i].selected) {
					selected = i;
				}
			}

			if (this.options.autoSelect && selected == null) {
				selected = first;
			}

			for (var i = 0; i < this.options.panels.length; i++) {
				this.add(this.options.panels[i], i == selected);
			}
		},

		add: function(item, selected) {
			if (typeof selected == 'undefined' && this.items.length == 0) {
				selected = true;
			}

			if (typeof item.id == 'undefined') {
				item.id = null;
			}

			if (typeof item.type == 'undefined') {
				item.type = 'panel';
			}

			if (typeof item.visible == 'undefined' || item.visible) {
				if (item.type == 'panel') {
					var panel = new TabbedPanel(this.panels, {
						type:		this.options.type,
						className:	item.className || null
					});

					if (!!selected) {
						for (var i = 0; i < this.items.length; i++) {
							if (this.items[i].panel)
								this.items[i].panel.hide();
						}

						this.current = item.id;
						panel.show();
					}
				}

				this.tabs.add({
					title: 		item.title,
					value:		item.id || null,
					icon:		item.icon || undefined,
					type:		item.type == 'panel' ? 'item' : item.type,
					escape:		typeof item.escape == 'undefined' ? true : item.escape,
					selected: 	!! selected,
					onSelect: 	function(item) { 
									if (item.type == 'panel') {
										this.select(item) 
									}
								}.bind(this, item)
				});

				if (item.type == 'panel') {
					if (item.onInitialize) {
						item.onInitialize(panel.container, !! selected);
					}

					item.panel = panel;

					if (selected && item.onActivate) {
						item.onActivate(item);
					}
				}
			}

			this.items.push(item);
		},

		select: function(item) {
			if (!item.panel) {
				/* We've not properly initialized yet, so we're not ready to be selected yet */
				return;
			}

			this.current = item.id;

			for (var i = 0; i < this.items.length; i++) {
				if (typeof this.items[i].panel != 'undefined') {
					this.items[i].panel.hide();
				}
			}

			item.panel.show();

			if (this.options.linked) {
				Widgets.TabbedPanels.activate(this.options.linked, this);
			}

			if (this.options.onChange) {
				this.options.onChange(this.current);
			}

			if (item.onActivate) {
				item.onActivate(item);
			}

			if (item.onShow) {
				item.onShow(item);
			}
		},

		clear: function() {
			this.current = null;
			this.tabs.unselect();

			for (var i = 0; i < this.items.length; i++) {
				if (typeof this.items[i].panel != 'undefined') {
					this.items[i].panel.hide();
				}
			}
		},

		get value() { return this.current; },
		set value(value) {
			this.tabs.value = value;
		},
	};

	Widgets.TabbedPanels.links = {};
	Widgets.TabbedPanels.register = function(name, panel) {
		if (typeof Widgets.TabbedPanels.links[name] == 'undefined') Widgets.TabbedPanels.links[name] = [];
		Widgets.TabbedPanels.links[name].push(panel);
	}
	Widgets.TabbedPanels.activate = function(name, panel) {
		for (var i = 0; i < Widgets.TabbedPanels.links[name].length; i++) {
			if (Widgets.TabbedPanels.links[name][i] != panel) {
				Widgets.TabbedPanels.links[name][i].clear();
			}
		}
	}


	CSS.insert(`
		.panels > .panel { display: block; height: 100%; } 
		.panels > .panel.hidden { display: none; } 
		.panels.pageType > .panel > .widget.periodSelector:first-child { margin-top: 24px; } 
		
		ui-window .panels.pageType > .panel > .widget:last-child { margin-bottom: 0; } 
	`);

	TabbedPanel = Class.create();
	TabbedPanel.prototype = {
		initialize: function(parent, options) {
			this.parent = parent;
			this.options = options;

			this.element = new Element('div', { className: 'panel ' + this.options.type + 'Type' });
			if (this.options.className) this.element.classList.add(this.options.className);
			this.parent.appendChild(this.element);

			this.container = this.element

			this.hide();
		},

		show: function() {
			this.element.show();

			document.fire('widget:render', this.container);
		},

		hide: function() {
			this.element.hide();
		}
	};



